import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog37.jpg'
import styles from './post-grid.module.css'

const PalmOil = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Je palmový tuk zdraví škodlivý?'}
        description={
          'Palmový tuk který je přidáván do mnoha potravin i pochutin, především kvůli jeho ceně a univerzálnímu použití.'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/palm-oil">
            Je palmový tuk zdraví škodlivý?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Je palmový tuk zdraví škodlivý?" date="12.09.23" />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />
              <h5 className={styles.postTitle}>V čem je palmový tuk</h5>
              <p className={styles.postText}>
                Palmový tuk který je přidáván do mnoha potravin i pochutin,
                především kvůli<b> jeho ceně a univerzálnímu použití. </b>Můžeme
                ho najít téměř ve všem, především tedy v
                <b> průmyslově zpracovaných potravinách. </b>Příkladem mohou být
                různé sušenky, čokolády ale i mléčné a masné výrobky. Také se
                hojně využívá na smažení v různých rychlých občerstveních.
                Můžeme ho najít ale i v<b> biopalivech </b>(kde se využívá
                převážně),<b> kosmetických přípravcích, </b>pracích prášcích či
                šampónech a mnoho dalších.
              </p>
              <h5 className={styles.postTitle}>Z čeho je získáván</h5>
              <p className={styles.postText}>
                Jedná se o rostlinný tuk<b> z plodu africké palmy </b>Elaeis
                quineesis, česky<b> Palma olejná. </b>Z plodů této rostliny se
                získává dvojí tuk, první ze semen plodu –<b> palmojádrový, </b>a
                druhý z oplodí palmy –<b> palmový</b>. Ty se svým složením
                poměrně liší, zvláště<b> složením mastných kyselin.</b>
              </p>
              <h5 className={styles.postTitle}>
                Je to rostlinný olej, tak čeho se bát
              </h5>
              <p className={styles.postText}>Ano, rostlinný je, ale svým složením se<b> spíše podobá tukům živočišným, </b>až na to, že oproti živočišným tukům<b> neobsahuje cholesterol. </b>Faktem také je, že palmový tuk je naším<b> největším zdrojem vitaminů A a E. </b>Díky poměrně<b> vysokému obsahu nasycených mastných kyselin </b>nedochází při vyrábění ztužených tuků (jako je např. margarín) ke vzniku trans mastných nenasycených kyselin.</p>

              <p className={styles.postText}>
              V nynější době ho opravdu najdeme<b> skoro ve všech potravinách</b>,  a to v poměrně velkém množství. Stejně jako s máslem nebo sádlem, okolo kterých chodí spoustu lidí po špičkách, tak i s palmovým tukem, bychom to<b> neměli přehánět.</b>
              </p>

              <p className={styles.postText}>Palmový olej obsahuje<b> 50 % nasycených mastných kyselin a palmojádrový až 85 %, </b>podobně je na tom i velmi oblíbený kokosový olej. Když si to porovnáme například se sádlem, to obsahuje “pouze” 40 %.</p>

              <p className={styles.postText}>A proč vůbec řešíme<b> nasycené mastné kyseliny</b>? Protože jejich zvýšený příjem nese různá<b> zdravotní rizika</b>, jako například riziko<b> kardiovaskulárních onemocnění</b>. Proto by jejich příjem neměl překračovat 10 % z celkového příjmu tuků. Je tedy vhodné vybírat spíše potraviny (a oleje), které jich obsahují minimum.    </p>
              <h5 className={styles.postTitle}>
              Jak je to s tou ekologií?
              </h5>
              <p className={styles.postText}>
              Palmový a palmojádrový olej je hojně zastoupen, v roce 2012 představoval<b> více než třetinu z veškeré celosvětové produkce tuků a olejů. </b>Jedním z důvodů je to, že palmové olejnice mají<b> jednu z nejvyšších úrovní výnosu </b> na plochu ze všech olejných plodin. Z 1 hektaru získáme 3 tuny oleje a při správném pěstování palma vydrží až 50 let.

              </p>

              <p className={styles.postText}>
              Bohužel se jedná hlavně o to,<b> kde se tato palma pěstuje</b>, a to na plantážích, na kterých byla<b> vymýcena a vypálena původní vegetace. </b>V oblastech Malajsie a Indonésie (hlavní producenti palmového oleje), je kvůli šíření těchto plantáží<b> likvidován </b>v obrovské ploše<b> tropický prales  </b>a v něm mnoho rostlinných a živočišných druhů, jako je i populace orangutanů, a to nemluvím o důležitém kyslíku, které rostliny přeměňují.
              </p>
              <h5 className={styles.postTitle}>
              Závěrem
              </h5>
              <p className={styles.postText}>Palmový olej z výživového hlediska není<b> nic, čeho bychom se měli bát, ale také nic, co bychom měli vyhledávat </b>a rozhodně bychom ho neměli konzumovat každý den. Co se ekologie týče, každý si udělejte vlastní názor. Pokud nechcete pěstování palem podporovat, jednoduše nekupujte výrobky, kde se palmový olej vyskytuje.  </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default PalmOil
